import {downloadXml, getFilenameFromContentDisposition, downloadFile} from '@/helpers/documents'

const actions = [
  {
    icon: "mdi-file-pdf-box",
    action: "downloadPdf",
    name: "Скачать PDF"
  },
  {
    icon: "mdi-content-copy",
    name: "Копировать документ",
    action: "copyDocument",
    divider: true,
    component: () => import('@/components/epi/journal/copy-document.vue')
  },
  {
    icon: "mdi-file-move-outline",
    name: "Конвертировать в ТД",
    action: "createTransitDeclaration",
  },
  {
    icon:"mdi-file-document-edit-outline",
    name:"Скорректировать",
    action:"adjustDocument",
  },
  {
    icon: "mdi-tray-arrow-down",
    name: "Скачать xml",
    action: "downloadXml"
  },
  {
    icon: "mdi-delete",
    name: "Удалить документ",
    action: "deleteDocument",
  },
  {
    icon: "mdi-account",
    name: "Изменить исполнителя",
    action: "changePerformer",
    divider: true,
    component: () => import('@/components/documents/change-performer.vue')
  },

]

const methods = {
  adjustDocument({id}){
    this.$store.dispatch('epi/createCorrection', {id}).then(res => {
      this.$success(`Создана корректировка - ${res.data}`)
    }).catch(() => this.$error())
  },
  getActionIndex(actionId) {
    const action = this.actions.find(i => i?.action === actionId)
    return this.actions.indexOf(action)
  },
  downloadXml({id}) {
    this.$store.dispatch("epi/getXml", id).then((res) => {
      downloadXml(res.data, getFilenameFromContentDisposition(res))
    });
  },
  downloadPdf({id}) {
    this.$store.dispatch('epi/downloadPdf', id).then((res) => {
      const name = getFilenameFromContentDisposition(res)
      downloadFile({data: res.data, type: 'application/pdf', name})
    }).catch(() => this.$error())
  },
  copyDocument({id, actionId}) {
    this.selectedId = id
    this.activeAction = this.getActionIndex(actionId)
    this.showComponent = true
  },
  deleteDocument({id, item}) {
    const value = !item.is_trash
    this.$store.dispatch('epi/toTrash', {id, value}).then(() => {
      this.$success("Документ удален")
      this.$store.dispatch('journal/fetchItems')
    }).catch(() => this.$error())
  },
  changePerformer({id, actionId}) {
    this.selectedId = id
    this.activeAction = this.getActionIndex(actionId)
    this.showComponent = true
  },
  createTransitDeclaration({id}){
    this.$store.dispatch('epi/createTransitDeclaration', id)
      .then((res) => {
        const transitId = res.data
        this.$success(`Создана ТД - ${transitId}`)
        const route = this.$router.resolve({
          path: `/transit/${transitId}`
        });
        window.open(route.href ,'_blank')
      }).catch(() => this.$error())
  }
}

export {
  methods, actions
}
