<template>
  <v-menu
    offset-y
    :close-on-content-click="false"
  >
    <template #activator="{ on, attrs }">
      <v-avatar
        v-if="item.client_division_id !== ''"
        :color="item.user_id === userId ? 'red': getAvatarClass(item, 3)"
        size="40"
        v-bind="attrs"
        v-on="on"
      >
        <span class="white--text ">{{ item.initials }}</span>
      </v-avatar>
    </template>
    <v-list>
      <v-list-item>ФИО <span class="mx-5 font-weight-bold">{{ item.fullname || 'Отсутствует' }}</span></v-list-item>
      <v-list-item v-if="item.email">
        E-mail <span class="mx-5 font-weight-bold">{{ item.email }}</span>
      </v-list-item>
      <v-list-item v-if="item.phone">
        Телефон <span class="mx-5 font-weight-bold">{{ item.phone }}</span>
      </v-list-item>
    </v-list>
  </v-menu>
</template>
<script>
export default {
  props:{
    item:{
      type:Object,
      required:true
    },
    userId:{
      validator:(prop) => typeof prop === 'number' || prop === null,
      required:true,
      default:null
    }
  },
  methods:{
    getAvatarClass(item, opacity = 3){
      if(item.user_id === this.userId ){
        return 'red'
      }else{
        if(item.is_deferred === true){
          return `red lighten-${opacity}`
        }
        if(item.client_division_id && item.client_division_id !== null && item.user_id === null){
          return `blue lighten-${opacity}`
        }
        return `blue lighten-3`
      }
    },
  }
}
</script>