<template>
  <journal-goods-list
    :id="id"
    :show.sync="isShow"
    :headers="headers"
    :title="title"
    :wares="items"
  >
    <template #header>
      <goods-list-header
        :declaration="declaration"
        :wares="items"
      />
    </template>
  </journal-goods-list>
</template>
<script>
import JournalGoodsList from "@/components/journal/journal-goods-list.vue";
import GoodsListHeader from "@/components/epi/journal/goods-list-header.vue";
import {getCostString, getGrossWeightString} from "@/helpers/text";
import JournalGoodsListMixin from "@/mixins/journal-goods-list.mixin";

export default {
  components:{
    GoodsListHeader,
    JournalGoodsList
  },
  mixins:[JournalGoodsListMixin],
  data(){
    return{
      declaration:{},
      items:[],
      headers:[
        {
          text:"№",
          value:"index"
        },
        {
          text:"Код товара",
          value:"tn_ved"
        },
        {
          text:"Наименование товара",
          value:"title"
        },
        {
          text:"Мест",
          value:"cargo_place_quantity"
        },
        {
          text:"Упаковка",
          value:"package_type_code"
        },
        {
          text:"Брутто",
          value:"gross_weight"
        },
        {
          text:"Стоимость",
          value:"cost"
        }
      ],
    }
  },
  methods:{
    setItems(wares){
      this.items = wares.map((item, idx) => {
        const {
          id,
          tn_ved,
          title,
          cargo_place_quantity,
          package_type_code,
          gross_weight,
          invoice_cost,
          invoice_currency_letter,
        } = item
        const cost = getCostString(invoice_cost, invoice_currency_letter)
        const grossWeight = getGrossWeightString(gross_weight)
        return {
          index:idx + 1,
          gross_weight:grossWeight,
          id,
          tn_ved,
          title,
          cargo_place_quantity,
          package_type_code,
          cost,

        }
      })
    },
    fetchDocument(){
      this.$store.dispatch(`epi/getDocumentInfo`, this.id).then(res => {
        this.declaration = res.data
        this.setItems(this.getWaresFromFirstShipment())
      }).catch(this.errorHandler)
    },
  }
}
</script>
