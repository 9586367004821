import {getJournalDeclarationTitle} from "@/helpers/text";

export default {
  props:{
    show:{
      required:true,
      type:Boolean
    },
    id:{
      type:Number,
      required:true,
    },
  },
  computed:{
    isShow:{
      get(){
        return this.show
      },
      set(value){
        this.$emit('update:show', value)
      }
    },
    title(){
      return getJournalDeclarationTitle(this.declaration)
    },
  },
  watch:{
    show:{
      handler(value){
        if(value){
          this.fetchDocument()
        }
      },
      immediate:true
    }
  },
  methods:{
    errorHandler(err){
      if(err.response.status === 403){
        this.$error('Доступ запрещен')
      }else{
        this.$error()
      }
    },
    getWaresFromFirstShipment(){
      const [shipment] = this.declaration.ware_shipments
      return shipment?.wares ?? []
    },
  }
}