import bufferMixin from "@/mixins/buffer.mixin";
import JournalUser from "@/components/journal/journal-user.vue";
import JournalActions from "@/components/journal/journal-actions.vue";

export default {
  mixins:[bufferMixin],
  components:{
    JournalUser,
    JournalActions,
  },
  data:() =>({
    rootUserId: null,
    activeAction: null,
    activeComponent: null,
    showComponent: false,
    selectedId: -1,
  }),
  computed:{
    options() {
      return {
        page: this.pagination.page,
        itemsPerPage: this.pagination.size,
      };
    },
    cardsPagination() {
      return {
        page: this.pagination.page,
        itemsPerPage: this.options.itemsPerPage,
        itemsLength: this.total,
        pageStart:
          this.pagination.page * this.pagination.size - this.pagination.size,
        pageStop: this.pagination.page * this.pagination.size,
      };
    },
    mobile() {
      return this.$vuetify.breakpoint.mobile;
    },
    userId() {
      return this.user?.user?.id || null;
    },
    isAdmin() {
      return !!(
        this.user?.authorities
          .filter((i) => i?.division?.id === this.user?.user?.activeDivisionId)
          .find((i) => i?.roleName.toString().includes("administrator")) ||
        false
      ); // Есть админские права
    },
  },
  watch: {
    activeAction() {
      this.activeComponent = this.actions[this.activeAction]?.component ?? null;
    },
  },
  methods:{
    splitTransportNumber(number) {
      return number
        ? number
          .split("/")
          .map((value, index, array) => {
            if (index % 2 === 0) {
              const next = array[index + 1]
              return next ? [value, next ].join("/"): value;
            }
          })
          .filter((i) => !!i)
        : null;
    },
    onPerform({func, args}) {
      const current = args.find((i) => {
        return i.actionId === func;
      });
      this.rootUserId = current.item.user_id;
      this.callFunction(func, ...args);
    },
    onCardsOptsUpdate({page, itemsPerPage}) {
      this.setPaginationPage(page);
      this.setPaginationSize(itemsPerPage);
    },
    getAvailableActions(config) {
      const actionNames = Object.keys(config);
      const actions = this.actions
        .filter((i) => {
          return actionNames.includes(i?.action) || i === null; // Проверка на соответствие типу документа
        })
        .filter((i) => {
          if (i === null) return true;
          if (i?.action) {
            return !config[i.action]
              .map((permission) => this.$can(permission))
              .includes(false); // Проверяем наличие всех разрешений
          }
          return false;
        });
      return actions.every((v) => v === null) ? [] : actions; // Если только пробелы то вернем пустой массив
    },
    getItemActions(item) {
      const status = {
        isRequest: !!(item.client_division_id && true && item.user_id === null), // Заявка
        hasUser: !!(item.user_id),
        isDeffered: !!item.is_deferred, // Если заявка и она отложена
        documentFromRequest: !!(item.client_division_id && true && item.user_id), // Заявка которой присвоен исполнитель
        myRequestDocument: (
          item.client_division_id === this.user?.user?.activeDivisionId
        ), // Документ который видит заявитель
        myDocument: (item.user_id === this.userId), // Мой документ
        isRegistered:!!item?.reg_num_a, // Зарегистрирован
        isTrash: item.is_trash, // Удален
        isFramed: !!item.reg_num_b, // Оформлен
        isAdjusted: (item.correction_kind === "КС"), // КС
        isAnnuled: (item.correction_kind === "АН"), // АН
        hasTelegram: !!item?.telegram_id
      };
      return this.getDocumentTypeActions(status);
    },

    getItemClass(item, opacity = 4) {
      let classList = `item-id-${item.id} `
      if(this.documentsInBuffer.includes(item.id)){
        return classList + `teal lighten-${opacity}`
      }else if (item.is_trash === true) {
        return classList + `grey lighten-${opacity}`;
      }else if (item.is_deferred === true) {
        return classList + `red lighten-${opacity}`;
      }else if (
        item.client_division_id &&
        item.user_id === null
      ) {
        return classList + `blue lighten-${opacity}`;
      }else {
        return classList
      }
    },
    getInitials({first_name = "", last_name = ""}) {
      const first = first_name || "";
      const last = last_name || "";
      return [first.slice(0, 1), last.slice(0, 1)]
        .join("")
        .trim()
        .toUpperCase();
    },
    getFullName({first_name = "", last_name = ""}) {
      const first = first_name || "";
      const last = last_name || "";
      return [first, last].join(" ").trim().toUpperCase();
    },
    callFunction(func, ...args) {
      func.length ? this[func](...args) : false;
    },
    setPaginationSize(size) {
      this.$store.commit("journal/SET_PAGINATION", {size});
      this.$store.dispatch("journal/fetchItems");
    },
    setPaginationPage(page) {
      this.$store.commit("journal/SET_PAGINATION", {page});
      this.$store.dispatch("journal/fetchItems");
    },
    getItemDeclarationType(type) {
      switch (type) {
      case "DTS1":
        return "ДТС-1";
      case "DTS2":
        return "ДТС-2";
      default:
        return "";
      }
    }
  }
}
