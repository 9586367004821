<template>
  <div>
    <span class="font-weight-medium">{{ id }}</span><br>
    <span class="text-caption font-weight-light"> {{
      declarationDate
    }}</span>
  </div>
</template>
<script>
export default {
  props:{
    id:{
      required:true,
      type:Number,
    },
    declarationDate:{
      required: true,
      validator:(v) => typeof v === 'string' || v === null
    }

  }
}
</script>
