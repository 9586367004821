<template>
  <v-menu offset-y>
    <template #activator="{ on, attrs }">
      <v-btn
        small
        dense
        :dark="!!item.actions.length"
        :disabled="!item.actions.length"
        class="elevation-0 white--text"
        v-bind="attrs"
        v-on="on"
      >
        &bull;&bull;&bull;
      </v-btn>
    </template>

    <v-list>
      <v-list-item-group>
        <template
          v-for="(action,idx) in item.actions"
        >
          <v-divider
            v-if="action === null"
            :key="`divider-${idx}`"
          />
          <v-list-item
            v-else
            :key="`item-${item.id}-action-${idx}`"
            :disabled="isDisabled({item, action})"
          >
            <v-list-item-content @click="callFunction(action.action,{id:item.id, actionId:action.action , item})">
              <v-list-item-title>
                <v-icon class="mr-2">
                  {{ action.icon }}
                </v-icon>{{ action.name }}
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </template>
      </v-list-item-group>
    </v-list>
  </v-menu>
</template>
<script>
export default {
  props:{
    item:{
      required:true,
      type:Object
    }
  },
  methods:{
    callFunction(func, ...args) {
      this.$emit('perform', {func, args})  
    },
    isDisabled({item, action}){
      if(action.action === "addComment"){
        if(!item.client_division_id || item.client_division_id === null){
          return true
        }
      }
      return false
    },
  }
}
</script>